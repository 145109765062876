const ContentStyle = theme => ({
    root: {
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexGrow: 1,
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: theme.palette.primary.light,
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            flexGrow: 1,
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: theme.palette.primary.light,
        },
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
            flexGrow: 1,
            padding: 0,
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: theme.palette.primary.light,
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '72%',
        marginBottom: '8%',
        // backgroundColor: 'green',
        // [theme.breakpoints.up('md')]: {
        //     paddingTop: 8,
        //     paddingBottom: 8,
        // },
        // [theme.breakpoints.up('lg')]: {
        //     height: 580,
        // },
        // [theme.breakpoints.up('xl')]: {
        //     height: 660,
        // },
    },
    screenshot: {
        display: 'flex',
        flexBasis: 0,
        flexGrow: 0.46,
        // backgroundColor: 'red',
        height: '100%',
    },
    spacing: {
        flexGrow: 0.08,
        height: '100%',
    },
    description: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexBasis: 0,
        flexGrow: 0.46,
        height: '100%',
        // backgroundColor: 'orange',
    }
})

// const ContentStyle = theme => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: theme.palette.primary.light,
//         display: 'flex',
//         flexDirection: 'column',
        // [theme.breakpoints.up('sm')]: {
        //     flexDirection: 'column',
        // },
        // [theme.breakpoints.up('md')]: {
        //     flexDirection: 'row',
        // },
        // [theme.breakpoints.up('lg')]: {
        //     flexDirection: 'row',
        // },
        // [theme.breakpoints.up('xl')]: {
        //     flexDirection: 'row',
        // },
//     },
//     rowContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         width: '100%',
//         // backgroundColor: 'white'
//     },
//     columnContainer: {
//         flex: 1,
//         display: 'flex',
//         flexDirection: 'column'
//     },
//     screenshotContainer: {
//         backgroundColor: 'green',
//         flexGrow: 1,
//         height: 420,
//         display: 'flex',
//         [theme.breakpoints.up('sm')]: {
//             flexGrow: 1,
//         },
//         [theme.breakpoints.up('md')]: {
//             flexGrow: 0,
//             display: 'block',
//             width: '41%',
//             height: '100%',
//             marginLeft: '13%',
//         },
//         [theme.breakpoints.up('lg')]: {
//             flexGrow: 0,
//             display: 'block',
//             width: '41%',
//             height: '100%',
//             marginLeft: '13%',
//         },
//         [theme.breakpoints.up('xl')]: {
//             flexGrow: 0,
//             display: 'block',
//             width: '41%',
//             height: '100%',
//             marginLeft: '13%',
//         },
//     },
//     infoContainer: {
//         backgroundColor: 'orange',
//         flexGrow: 1,
//         height: 420,
//         [theme.breakpoints.up('sm')]: {
//             flexGrow: 1,
//         },
//         [theme.breakpoints.up('md')]: {
//             flexGrow: 1,
//             height: '100%',
//             marginRight: '13%',
//         },
//         [theme.breakpoints.up('lg')]: {
//             flexGrow: 1,
//             height: '100%',
//             marginRight: '13%',
//         },
//         [theme.breakpoints.up('xl')]: {
//             flexGrow: 1,
//             height: '100%',
//             marginRight: '13%',
//         },
//     },
//     screenshot: {
//         display: 'block',
//         backgroundColor: 'white',
//         width: '70%',
//         height: '80%',
//         margin: 'auto',
//         [theme.breakpoints.up('md')]: {
//             marginTop: '10%',
//             width: '80%',
//         },
//         [theme.breakpoints.up('lg')]: {
//             marginTop: '10%',
//             width: '80%',
//         },
//         [theme.breakpoints.up('xl')]: {
//             marginTop: '6%',
//             width: '80%',
//         },
//     },
//     image: {
//         display: 'block',
//         width: '100%',
//         height: 'auto',
//         margin: 'auto'
//     }
// });
export default ContentStyle;