import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { theme } from './themes'
import { Header } from './components/header';
import { Content } from './components/content';
import { Footer } from './components/footer';
import AppStyle from './AppStyle';

class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Header />
          <Content />
          <Footer />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(AppStyle, { withTheme: true })(App);
