import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Image from './Image';

import ScreenshotStyle from './ScreenshotStyle';


class Screenshot extends Component {
    renderInColumn = (w) => {
        return (w === 'xs' || w === 'sm');
    }

    render() {
        const { classes, className: classNameProp, width } = this.props;
        const smallScreen = this.renderInColumn(width);
        return(
            <div className={ classNames(classes.root, classNameProp) }>
                {smallScreen ? <img 
                    style={{
                        width: '100%',
                    }}
                    alt='Screenshot'
                    src={require('../../assets/images/im_screenshot.png')}
                /> :
                <Image 
                    src={require('../../assets/images/im_screenshot.png')}
                    width={'100%'}
                    height={500}
                />
                }
            </div>
        );
    }
}


Screenshot.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withWidth()(withStyles(ScreenshotStyle, { withTheme:true })(Screenshot));