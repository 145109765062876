import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DescriptionStyle from './DescriptionStyle';


const APP_STORE_LINK = 'https://itunes.apple.com/app/elo-see-whos-here/id1445516825?mt=8';
const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.tinkerstuff.zeta';

class Description extends Component {
    onAppStoreClick = () => {
        console.log('App Store')
       window.open(APP_STORE_LINK, '_blank');
    }

    onGooglePlayClick = () => {
        console.log('Google Play')
        window.open(PLAY_STORE_LINK, '_blank');
    }

    render() {
        const { classes, className: classNameProp } = this.props;
        return(
            <div className={ classNames(classes.root, classNameProp) }>
            <Typography className={classes.title}>
                See who's here!
            </Typography>
            <Typography className={classes.summary}>
                Say hello to the people in the same room as you. Use the Elo app to see the names and faces of people here and now, who are also looking to get to know you. Just walk over and introduce yourself!
            </Typography>
            <Typography className={classes.summary}>
                To use Elo, simply launch the app and browse the Nearby tab to see who else is here. You can choose to be visible to others from the Status tab. You can update your status to show them what you’re into, for example “came back from Hawaii vacation”, “looking for French lessons” etc. 
            </Typography>
            <Typography className={classes.summary}>
                Enjoy your company!
            </Typography>
            <div className={classes.buttonContainer}>
                <button className={classes.button} onClick={this.onAppStoreClick}>
                    <img alt='App Store Button'
                        src={require('../../assets/icons/ic_app_store.png')}
                        // className={classes.image}
                        style={{
                            width: '90%',
                            height: 'auto',
                            marginLeft: -12,
                            cursor: 'pointer'
                            // marginRight: 8,
                        }}
                        />
                </button>
                <button className={classes.button} onClick={this.onGooglePlayClick}>
                    <img alt='Google Play Button'
                        src={require('../../assets/icons/ic_google_play.png')}
                        style={{
                            width: '100%',
                            cursor: 'pointer'
                            // marginLeft: 8,
                            // marginRight: 8,
                        }}
                        />
                </button>
            </div>
            </div>
        );
    }
}


Description.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(DescriptionStyle, { withTheme:true })(Description);