const ScreenshotStyle = theme => ({
    root: {
        display: 'block',
        width: '90%',
        margin: 'auto',
        // backgroundColor: 'green',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.up('lg')]: {
            // width: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            // width: '100%',
        },
    },
});
export default ScreenshotStyle;