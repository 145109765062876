import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ContentStyle from './ContentStyle';
import Screenshot from './Screenshot';
import Description from './Description';


class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentHeight: null,
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.updateWindowHeight);
        this.setState({
            contentHeight: Math.ceil(window.innerHeight * 0.6)
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowHeight);
    }

    updateWindowHeight = () => {
        console.log(`window height: ${window.innerHeight}`)
        this.setState({
            contentHeight: Math.ceil(window.innerHeight * 0.6)
        });
    }

    renderInColumn = (w) => {
        return (w === 'xs' || w === 'sm');
    }

    // render() {
    //     const { 
    //         classes, 
    //         className: classNameProp,
    //         width, 
    //     } = this.props;
    //     const renderInColumn = this.renderInColumn(width);
    //     return(
    //         <div className={ classNames(classes.root, classNameProp) }>
    //             <div className={renderInColumn? classes.columnContainer : classes.rowContainer}>
    //                 <div className={classes.screenshotContainer}>
    //                     <div className={classes.screenshot}>
    //                     <img className={classes.image} 
    //                         alt='Screenshot'
    //                         src={require('../../assets/images/im_screenshot.png')}
    //                     />
    //                     </div>
    //                 </div>
    //                 <div className={classes.infoContainer}></div>
    //             </div>
    //         </div>
    //     );
    // }

    renderRowContent = () => {
        const { contentHeight } = this.state;
        if (!contentHeight) {
            return null;
        }
        const { classes } = this.props;
        return (
            <div className={classes.container} style={{height:contentHeight}}>
                <div className={classes.screenshot}>
                    <Screenshot />
                </div>
                <div className={classes.spacing}></div>
                <div className={classes.description}>
                    <Description />
                </div>
            </div>
        )
    }

    renderColumnContent = () => {
        return (
            <List>
                <ListItem>
                    <Screenshot />
                </ListItem>
                <ListItem>
                    <Description />
                </ListItem>
            </List>
        )
    }

    render() {
        const {
            classes, 
            className: classNameProp,
            width,
        } = this.props;
        const renderInColumn = this.renderInColumn(width);
        return (
            <div className={classNames(classes.root, classNameProp)}>
                { renderInColumn ? this.renderColumnContent() : this.renderRowContent()}
            </div>
        );
    }
}


Content.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withWidth()(withStyles(ContentStyle, { withTheme:true })(Content));