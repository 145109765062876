const HeaderStyle = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 74,
        // backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('sm')]: {
            height: 98,
        },
        [theme.breakpoints.up('md')]: {
            height: 122,
        },
        [theme.breakpoints.up('lg')]: {
            height: 146,
        },
        [theme.breakpoints.up('xl')]: {
            height: 174,
        },
    },
    logo: {
        width: 40,
        height: 40,
        marginLeft: 'auto',
        // marginRight: 'auto',
        [theme.breakpoints.up('sm')]: {
            width: 48,
            height: 48,
        },
        [theme.breakpoints.up('md')]: {
            width: 52,
            height: 52,
            marginLeft: 50,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            width: 62,
            height: 62,
            marginLeft: 60,
            marginRight: 0,
        },
        [theme.breakpoints.up('xl')]: {
            width: 72,
            height: 72,
            marginLeft: 70,
            marginRight: 0,
        },
    },
    title: {
        fontSize: 20,
        fontWeight: '400',
        marginRight: 'auto',
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 24,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 28,
            marginRight: 0,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 34,
            marginRight: 0,
        },
    }
});
export default HeaderStyle;