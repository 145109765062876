const DescriptionStyle = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
            alignItems: 'flex-start',
        },
        [theme.breakpoints.up('xl')]: {
            alignItems: 'flex-start',
        },
    },
    title: {
        marginTop: 20,
        fontSize: 20,
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 0,
            fontSize: 28,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 0,
            fontSize: 36,
        },
    },
    summary: {
        marginTop: 20,
        fontSize: 16,
        textAlign: 'justify',
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('md')]: {
            marginTop: 12,
            textAlign: 'left',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 16,
            textAlign: 'left',
            fontSize: 20,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 28,
            textAlign: 'left',
            fontSize: 20,
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 32,
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('xl')]: {
            justifyContent: 'flex-start',
        },
    },
    button: {
        width: 160,
        // maxWidth: 180,
        // maxHeight: 60,
        // display: 'flex',
        border: 'none',
        background: 'none',
        outline: 'none',
        padding: 0,
        [theme.breakpoints.up('md')]: {
            width: 130,
        },
        [theme.breakpoints.up('lg')]: {
            width: 160,
        },
        [theme.breakpoints.up('xl')]: {
            width: 180,
        },
    }
});
export default DescriptionStyle;