const FooterStyle = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 52,
    },
    copyright: {
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 12,
        color: theme.palette.text.secondary,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
        },
    }
});
export default FooterStyle;