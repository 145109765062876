import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Avatar  from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import HeaderStyle from './HeaderStyle';


class Header extends Component {
    render() {
        const { classes, className: classNameProp } = this.props;
        return(
            <div className={ classNames(classes.root, classNameProp) }>
                <Avatar 
                    src={require('../../assets/icons/ic_logo.png')}
                    className={classes.logo}
                />
                <Typography className={classes.title}>
                    Elo
                </Typography>
            </div>
        );
    }
}


Header.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(HeaderStyle, { withTheme:true })(Header);