import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#EF5350',
            light: '#FF867C',
            dark: '#B61827',
            contrastText: '#fff',
        }
    }
});
export { theme };